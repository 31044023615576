<template>
  <div class="content-box vision-box">
    <!-- <v-Header :isShowTitle="true" :showback="true" tittle="视力检查" @back="$router.go(-1)">
    </v-Header> -->
    <div v-wechat-title="'视力检查'"></div>
    <div class="page-content">
      <div class="vision-box-content">
        <div class="flex flex-align-items vision-title-block" style="justify-content:space-between">
          <div style="position: relative">
            <audio id="myAudio" hidden="true" controls ref="myAudio" @ended="overAudio">
              <source :src="playSrc" type="audio/mpeg" />
            </audio>
            <!-- <img v-if="!isPlay" src="../../assets/imgs/c.png" alt="" style="width:24px" /> -->
            <div v-if="isPlay" class="mountain"></div>
          </div>
          <div>
            <div style="width:100%;text-align:center"><img src="../../assets/imgs/eye.png" alt="" />{{
                $store.state.eyeText
            }}</div>
          </div>
          <div class="align-right">
            <span v-if="ins == 0" class="right-word" @click="handleStart('highFarIndex')">低视力检查</span>
          </div>
        </div>

        <div class="vision-content">
          <!-- 第{{ ins + 1 }}行
          {{ text }} -->
          <ul class="list">
            <div id="echart" class="divc">
              <li v-for="(item, index) in $farVisionList" :key="index" v-show="ins == index" v-touch:left="onSwipeLeft"
                v-touch:right="onSwipeRight" v-touch:down="onSwipeDown" v-touch:up="onSwipeUp"
                class="flex flex-align-items flex-justify-content">
                <div :class="
                  currentItem.class == 'type1'
                    ? 'type1'
                    : currentItem.class == 'type2'
                      ? 'type2'
                      : currentItem.class == 'type3'
                        ? 'type3'
                        : 'type4'
                ">
                  <!-- <i class='icon-E iconfont'
                    :style="{fontSize:(width*item.scale)+'px'}" ></i> -->
                  <img src="../../assets/imgs/E.png" :style="{ width: width * item.scale + 'px' }" alt="" />
                </div>
              </li>
            </div>
            <div class="clock-box flex flex-align-items">
              <i v-show="n == 3 || n == 2 || n == 1" class="clock-circle"></i>
              <i v-show="n == 3 || n == 2" class="clock-circle"></i>
              <i v-show="n == 3" class="clock-circle"></i>
            </div>
          </ul>
        </div>
        <div class="flex bottom-box" style="width: calc(100% - 60px);padding-bottom: 30px;">
          <div class="flex-1">
            <img src="../../assets/imgs/up-icon.png" alt="" @click="onSwipeUp" />
          </div>
          <div class="flex-1">
            <img src="../../assets/imgs/down-icon.png" alt="" @click="onSwipeDown" />
          </div>
          <div class="flex-1">
            <img src="../../assets/imgs/cancel.png" alt="" @click="cancel" />
          </div>
          <div class="flex-1">
            <img src="../../assets/imgs/left-icon.png" alt="" @click="onSwipeLeft" />
          </div>
          <div class="flex-1">
            <img src="../../assets/imgs/right-icon.png" alt="" @click="onSwipeRight" />
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="showTip" round :style="{ width: '80%' }" class='tip-pop'  @close="onClose">
        <div class='tip-pop-content'>
          <div class='content-title'> 
            <audio id="myAudio2" hidden="true" controls ref="myAudio2" @ended="overAudio">
              <source :src="model.src1" type="audio/mpeg" />
            </audio> {{ model.name }}
            <div v-if="isPlay" class="mountainCust"></div>
          </div>
          <tip-content :index='10'></tip-content>
          <!-- <video ref="ref_video" :src="model.src" controls="controls" @close="onClose"></video> -->
          <div class='flex bottom-btn' style="margin: 0px; padding: 0px;">
            <!-- <div class='left-btn'>查看演示</div> -->
            <div class='right-btn' style="width:100%;margin: 0px; padding: 0px;font-size:.5rem;border-radius: unset;"
              @click="handleStart('highFarIndex')">
              开始检查
            </div>
          </div>
        </div>
      </van-popup>
  </div>
</template>
<script>
import { Dialog } from "vant";
import tipContent from '../../components/tipContent.vue'
export default {
  components: {
    tipContent,
  },
  data() {
    return {
      model:{
          name: '高度近视检查',
          note1: '',
          note2: '',
          src1:'/assets/Amsler/audios/2.0.mp3',
          // img: require('../assets/imgs/home-2.png'),
          src: '/assets/Amsler/audios/2.0.mp3',
          desc: ``,
        },
      ins: 0, //第几行
      width:
        7.272 /
        (((this.$store.state.sys_param.Dpi * 1) /
          this.$store.state.sys_param.PPI) *
          2.45),
      innerIns: 0,
      n: 5, //计时器秒数
      infoMessage: "",
      showTip:false,
      currentItem: {},//当前E 开口方向
      currentCorrectCount_left: 0,
      currentCorrectCount_right: 0,
      currentErrorCount_left: 0,
      currentErrorCount_right: 0,
      isNextStep: true,
      hasError: false,
      timer: null, //计时器
      result: null,
      // height:95
      isPlay: true,
      tmpArr: [0, 1, 2, 3],
      text: null,
      // playSrc:'/assets/Amsler/audios/1.1.mp3',
    };
  },

  mounted() {
    document.addEventListener("WeixinJSBridgeReady", function () {
      console.log("WeixinJSBridgeReady");
      document.getElementById('myAudio').play(); //视频自动播放
    }, false);
    if (window.name == 1) {
      window.name = 2;
      window.location.reload();
    }

    var video = document.querySelector('audio');
    document.addEventListener('visibilitychange', () => {
      // 当页面隐藏时候
      if (document.visibilityState === 'visible') {
        // 视频暂停
        this.isPlay = false
        // video.play();
      } else {
        // 当页面显示时视频开始播放
        video.pause();
      }
    });
  },

  beforeDestroy() {
    clearInterval(this.timer);
    document.getElementById('myAudio').pause();
  },
  created() {
    let _this = this;
    _this.n = sessionStorage.getItem("clock")
      ? sessionStorage.getItem("clock")
      : this.$time;
    if (JSON.parse(sessionStorage.getItem("isInterval"))) {
      this.timer = setInterval(() => {
        if (_this.n > 0) {
          _this.n--;
        } else {
          _this.cancel();
          _this.isNext();
        }
      }, 1000);
    }
    if (this.tmpArr.length > 0) {
      let c = this.tmpArr[Math.floor(Math.random() * this.tmpArr.length)]
      this.currentItem = this.$rowList[c];
      this.tmpArr.splice(this.tmpArr.indexOf(c), 1)
    } else {
      this.tmpArr = [0, 1, 2, 3]
      let c = this.tmpArr[Math.floor(Math.random() * this.tmpArr.length)]
      this.currentItem = this.$rowList[c];
      this.tmpArr.splice(this.tmpArr.indexOf(c), 1)
    }
    this.$httpProxy.get('/api/visual/get', { Id: this.$route.query.userId }).then((res) => {
      if (res.data.Success) {
        let initIni = res.data.ResData.Js
        if (Object.keys(initIni).length > 0) {
          let i = 0, obj = Object.keys(initIni), tmp = {};
          for (let j = 0; j < obj.length; j++) {
            if (initIni[obj[j]][0].Type == 1) {
              tmp = initIni[obj[j]][0];
              break;
            }
          }
          if (Object.keys(tmp).length > 0) {
            if (this.$store.state.eyeText == "左眼") {
              i = tmp.LeftMax;
            } else {
              i = tmp.RightMax;
            }
            let c = this.$farVisionList.findIndex(e => e.decimalRecord == i) || 0;
            if (c - 1 > 0) {
              this.ins = c - 1
            } else {
              this.ins = 0;
            }
          }
        }
      } else {
        console.log(res.data)
      }
    }).catch(e=>{
      console.log(e)
    })
  },
  computed: {
    playSrc() {
      if (this.$store.state.eyeText == "左眼") {
        return '/assets/Amsler/audios/1.1.mp3'
      } else {
        return '/assets/Amsler/audios/1.2.mp3'
      }
    },
  },
  watch: {
    result: {
      deep: true,
      handler(o) {
        if (o.indexOf("左") != -1) {
          this.onSwipeLeft();
        } else if (o.indexOf("右") != -1) {
          this.onSwipeRight();
        } else if (o.indexOf("上") != -1) {
          this.onSwipeUp();
        } else if (o.indexOf("下") != -1) {
          this.onSwipeDown();
        }
        // this.$refs['voiceRef'].start()
      },
    },
    infoMessage: {
      immediate: true,
      deep: true,
      handler(o) {
        if (o) {
          let str = this.$store.state.eyeText == "左眼" ? "右眼" : "完成";
          let c = "";
          if (this.$store.state.rightEye) {
            c = `右眼:${this.$store.state.rightEye}`;
          }
          let text = `左眼:${this.$store.state.leftEye}\r\n\r\n${c}`;
          Dialog.confirm({
            title: "您的检查结果",
            message: text,
            cancelButtonText: "重新检查",
            confirmButtonText: "检查" + str,
          })
            .then(async (action, done) => {
              if (action == "confirm") {
                if (str == "右眼") {
                  this.$store.commit("set_currentErrorCount_left", this.currentErrorCount_left);
                  this.$store.commit("set_currentCorrectCount_left", this.currentCorrectCount_left);
                }
                this.$store.commit("set_eye_text", str);
                if (this.$store.state.eyeText == "完成") {
                  let userid = await this.$vlf.getItem('userId');
                  let from = await this.$vlf.getItem('from')
                  if (from) {
                    this.$httpProxy.post(`/${from}/InsertEyes`, {
                      UserId: userid,
                      Type: 1,
                      LeftMax: this.$store.state.leftEye.split('(')[0].replace(' ', ''),
                      LeftMin: this.$store.state.leftEye.split('(')[1].replace(')', ''),
                      RightMax: this.$store.state.rightEye.split('(')[0].replace(' ', ''),
                      RightMin: this.$store.state.rightEye.split('(')[1].replace(')', ''),
                      LeftCorrectCount: this.$store.state.currentCorrectCount_left,
                      LeftErrorCount: this.$store.state.currentErrorCount_left,
                      RightErrorCount: this.currentErrorCount_right,
                      RightCorrectCount: this.currentCorrectCount_right,
                    }).catch(e => {
                      console.log(e)
                    })
                  }
                  this.$httpProxy.post('/api/visual/InsertEyes', {
                    VisualId: this.$route.query.visualId,
                    Type: 1,
                    LeftMax: this.$store.state.leftEye.split('(')[0].replace(' ', ''),
                    LeftMin: this.$store.state.leftEye.split('(')[1].replace(')', ''),
                    RightMax: this.$store.state.rightEye.split('(')[0].replace(' ', ''),
                    RightMin: this.$store.state.rightEye.split('(')[1].replace(')', ''),
                    LeftCorrectCount: this.$store.state.currentCorrectCount_left,
                    LeftErrorCount: this.$store.state.currentErrorCount_left,
                    RightErrorCount: this.currentErrorCount_right,
                    RightCorrectCount: this.currentCorrectCount_right,
                  }).then((res) => {
                    if (res.data.Success) {
                      // this.$router.go(-1);
                      // this.$router.push({ name: "index" });
                        if(from){
                            this.$router.push({ name: "index",query: {from:from,userid:userid}});
                        }else{
                            this.$router.push({ name: "index"});
                        }
                    } else {
                      this.$message.warning('出错了')
                    }
                  })

                } else {
                  window.location.reload();
                  //  this.$router.go(-1);
                }
                // this.$router.go(0);
              } else {
                done();
              }
            })
            .catch(() => {
              // this.$router.go(0);
              window.location.reload(); 
              // this.$router.go(0);
            });
        }
      },
    },
  },
  methods: {
    onClose() {
      document.getElementById('myAudio').pause();
      // this.$refs.ref_video.pause()
    },
    overAudio() {
      this.isPlay = false
    },
    // 开始检查
    async handleStart(item) {
      window.name = 1;
      this.$store.commit('set_left_eye', '')
      this.$store.commit('set_right_eye', '')
      this.$store.commit('set_eye_text', '左眼')
      let userid = await this.$vlf.getItem('userId')
      this.$httpProxy.post('/api/visual/insert', { UserId: userid }).then((res) => {
        if (res.data.Success) {
          this.$router.replace(
            {
              name: item,
              query: {
                userId: userid,
                visualId: res.data.ResData
              }
            }
          )
        } else {
          this.$message.warning('出错了')
        }
      })
    },

    onSwipeUp() {
      if (this.currentItem.correctAnswer == "top") {
        this.$farVisionList[this.ins].correctCount++;
      }
      this.$farVisionList[this.ins].hasAnswerCount++;
      this.animeCheck(0, -1000)
    },
    onSwipeDown() {
      if (this.currentItem.correctAnswer == "bottom") {
        this.$farVisionList[this.ins].correctCount++;
      }
      this.$farVisionList[this.ins].hasAnswerCount++;
      this.animeCheck(0, 1000)
    },
    onSwipeLeft() {
      if (this.currentItem.correctAnswer == "left") {
        this.$farVisionList[this.ins].correctCount++;
      }
      this.$farVisionList[this.ins].hasAnswerCount++;
      this.animeCheck(-1000, 0)
    },
    onSwipeRight() {
      if (this.currentItem.correctAnswer == "right") {
        this.$farVisionList[this.ins].correctCount++;
      }
      this.$farVisionList[this.ins].hasAnswerCount++;
      this.animeCheck(1000, 0)
    },
    cancel() {
      // this.$message.error('你放弃回答');
      this.$farVisionList[this.ins].hasAnswerCount++;

      this.isNext();
    },
    animeCheck(left = 0, rigth = 0, next) {
      let div = document.getElementById('echart');
      div.style = `-webkit-transform: translate(${left}px,${rigth}px); transition: 1s ease 0s; `
      setTimeout(() => {
        div.style = `-webkit-transform: translate:none; transition: 0s ease 0s;`
        this.isNext();
      }, 1000)
    },
    isNext() {
      if (this.isNextStep) {
        this.n = this.$time;
        //答过的数量小于视标数量
        if (
          this.$farVisionList[this.ins].hasAnswerCount <=
          this.$farVisionList[this.ins].count - 1
        ) {
          if (this.tmpArr.length > 0) {
            let c = this.tmpArr[Math.floor(Math.random() * this.tmpArr.length)]
            this.tmpArr.splice(this.tmpArr.indexOf(c), 1)
            this.currentItem = this.$rowList[c];
          } else {
            this.tmpArr = [0, 1, 2, 3]
            let c = this.tmpArr[Math.floor(Math.random() * this.tmpArr.length)]
            this.tmpArr.splice(this.tmpArr.indexOf(c), 1)
            this.currentItem = this.$rowList[c];
          }
        } else {
          if (
            this.$farVisionList[this.ins].correctCount >=
            this.$farVisionList[this.ins].minCorrectCount
          ) {

            if (this.ins == this.$farVisionList.length - 1) {
                this.isNextStep = false;
                this.infoMessage =
                  "你的视力为" + this.$farVisionList[this.ins].fivePointsRecord;
                this.setCount(this.ins);
                this.setEyeVal(this.ins)
                clearInterval(this.timer);
                return;
              } else {
                if (this.$farVisionList[this.ins+1].hasAnswerCount){
                  this.isNextStep = false;
                  this.infoMessage =
                    "你的视力为" + this.$farVisionList[this.ins].fivePointsRecord;
                  this.setCount(this.ins);
                  this.setEyeVal(this.ins)
                  clearInterval(this.timer);
                return;
                }else{
                  this.ins++;
                }
              }
          }  else {
            // 如果正确数量小于最少正确量，往前跳一级
            // this.hasError = true;
            // 判断上一级是否答过
            if (this.ins >= 1) {
              if (this.$farVisionList[this.ins - 1].correctCount >= this.$farVisionList[this.ins - 1].minCorrectCount) {
                this.isNextStep = false;
                this.infoMessage = '你的视力为' + this.$farVisionList[this.ins - 1].fivePointsRecord +
                  '正确数量为：' + this.$farVisionList[this.ins - 1].correctCount +
                  '答题数量为：' + this.$farVisionList[this.ins - 1].hasAnswerCount;
                this.setCount(this.ins - 1);
                this.setEyeVal(this.ins - 1)
                clearInterval(this.timer);
              } else {
                this.ins--;
              }
            } else {
              this.showTip=true;
              clearInterval(this.timer);
            }
          }
        }
      }
    },
    setCount(o) {
      if (this.$store.state.eyeText == "左眼") {
        this.currentCorrectCount_left = this.$farVisionList[o].correctCount;
        this.currentErrorCount_left = parseInt(this.$farVisionList[o].hasAnswerCount) - this.$farVisionList[o].correctCount;
      } else {
        this.currentCorrectCount_right = this.$farVisionList[o].correctCount;
        this.currentErrorCount_right = parseInt(this.$farVisionList[o].hasAnswerCount) - this.$farVisionList[o].correctCount;
      }
    },
    setEyeVal(rowIndex) {
      if (this.$store.state.eyeText == "左眼") {
        this.$store.commit(
          "set_left_eye",
          `${(this.$farVisionList[rowIndex].decimalRecord).toFixed(2)} (${(this.$farVisionList[rowIndex].fivePointsRecord).toFixed(1)
          })`
        );
      } else {
        this.$store.commit(
          "set_right_eye",
          `${(this.$farVisionList[rowIndex].decimalRecord).toFixed(2)} (${(this.$farVisionList[rowIndex].fivePointsRecord).toFixed(1)
          })`
        );
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/less/vision.less";
.content-title {
    position: relative;
    .lh(60);
    color: #000;
    .fs(40);
    .pt(20);
    .pb(30);
    background: #f5f6f8;
  }
.tip-pop-content {
  font-size: 0.45rem;
    .bottom-btn {
      .pt(20);
      background: #f5f6f8;
    }

    .left-btn {
      background: #FF9D30;
      color: #fff;
      .h(100);
      .lh(100);
      width: 49%;
    }

    .right-btn {
      .ml(20);
      background: #0090FF;
      color: #fff;
      .h(100);
      .lh(100);
      width: 49%;
    }
  }

.divc {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
</style>
